.entry{
  &__top_onboarding{
    border-bottom: none;
  }

  &__btns_onboarding{
    align-items: stretch;
  }

  &__button_onboarding{
    padding: 32px 16px !important;
    height: initial;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;

    svg{
      width: 32px;
      height: 32px;
      margin: 0 0 12px;
    }
    span{
      display: inline-block;
      text-align: center;
    }
  }
}