.select{
  > div > div{
    background-color: white !important;
  }
}

.card__information_custom{
  p{
    font-size: 14px;
    &:not(:last-child){
      margin-bottom: 16px;
    }
  }

  ul{
    padding-left: 24px;
    padding-bottom: 32px;
  }
  li{
    font-size: 14px;
    + li{
      margin-top: 6px !important;
    }
  }
}

.field__date{
  > div > div{
    background-color: white !important;
    border-color: white !important;
  }

  button{
    background-color: white !important;
  }
}

.upload{
  &_result{
    margin-top: -16px;
  }
}