// Abstract
@import "../../01-abstract/mixins";

.page-dashboard__header {
  background-color: var(--color__background-white);

  .page-dashboard__container {
    grid-template-columns: 160px minmax(0, 1fr) 190px;

    @media (max-width: 1565px) {
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-start;
      align-items: center;
      padding-left: 44px;
      padding-right: 44px;
      gap: 0;
    }
    @include mqTabletLandscape {
      display: grid;
      gap: 8px;
      grid-template-columns: 190px minmax(0, 1fr) 190px;
      padding-left: 26px;
      padding-right: 26px;
    }

    @include mqPhoneLarge {
      grid-template-columns: 60px minmax(0, 1fr) 60px;
    }
  }

  &-sidebar {
    display: none;
    align-items: center;

    @include mqTabletLandscape {
      display: block;
    }
  }
  &-hamburger {
    max-height: 30px;
    .toggle-icon {
      cursor: pointer;
      max-width: 28px;
    }
    .toggle-line {
      background: var(--color__background-dark);
    }
  }

  &-logo {
    display: flex;
    align-items: center;

    @media (max-width: 1365px) {
      flex: 50%;
      max-width: 50%;
    }

    @include mqTabletLandscape {
      justify-content: center;
      max-width: initial;
    }
    a {
      display: inherit;
      padding: 0;
    }
    img {
      height: auto;
      width: auto;
      max-height: 65px;

      &.svg-logo {
        height: 65px
      }
    }
  }

  &-nav {
    @media (max-width: 1565px) {
      flex: 1;
    }

    @media (max-width: 1365px) {
      order: 5;
      flex: 100%;
      max-width: 100%;
      border-top: 1px solid var(--color__border-hr);
    }

    @include mqTabletLandscape {
      display: none;
    }
    .navbar {
      box-shadow: none;
    }
    svg {
      display: none;
    }
    .locked-feature-icon svg {
      display: inline-block;
    }

    .navbar-innr {
      justify-content: center;
    }

    .navbar-menu,
    .navbar-btns {
      margin: 0;
      align-items: center;
      justify-content: flex-end;

      a {
        color: var(--color__text-link);
        text-transform: uppercase;
        letter-spacing: 0.3px;
        opacity: 0.6;
        text-decoration: none;
        display: block;
        overflow: hidden;
        line-height: 1.5;
        font-weight: 400;
        text-align: center;

        &:hover,
        &:focus {
          opacity: 1;
        }
        &.disable {
          opacity: 0.3;
          pointer-events: none;
        }
      }
      > li {
        padding: 0;

        > a {
          padding: 27px 16px;
          position: relative;

          &:before {
            content: "";
            position: absolute;
            background: linear-gradient(180deg, #4d7cfe 0%, #213798 100%);

            bottom: 0;
            border-radius: var(--border__radius-desktop);
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;
            left: 0;
            height: 5px;
            width: 100%;
            transition: all 250ms ease;
            transform: translateY(3px);
            opacity: 0;
            visibility: hidden;
          }

          // &.active{
          // 	opacity: 1;
          // 	&:before{
          // 		opacity: 1;
          // 		visibility: visible;
          // 		transform: translateY(0);
          // 	}
          // }
        }

        &.active {
          > a {
            opacity: 1;
            &:before {
              opacity: 1;
              visibility: visible;
              transform: translateY(0);
            }
          }
        }
      }
    }

    .navbar-btns {
      a {
        opacity: 1;
        span {
          color: var(--color__text-primary);
          opacity: 1;
        }

        &:before {
          display: none !important;
        }
      }
    }
  }

  &-user {
    display: flex;
    align-items: center;

    @media (max-width: 1365px) {
      flex: 50%;
      max-width: 50%;
      justify-content: flex-end;
    }
    @include mqTabletLandscape {
      max-width: initial;
    }
  }

  .topbar-nav {
    margin: 0;
    &-item {
      position: relative;
      margin: 0;
      padding: 0;
      display: flex;
      flex-flow: row-reverse;
      align-items: center;

      > .MuiBox-root-7 {
        // display: none;
      }
    }

    // user image
    .toggle {
      &-tigger {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;

        height: initial;
        width: initial;
        padding: 20.5px 0;
        line-height: initial;
        background: transparent;

        .user{
          &-img{
            width: 34px;
            height: 34px;
            line-height: 34px;
            margin-right: 12px;
            border-radius: 50%;
            overflow: hidden;
            background-color: var(--color__background-gray);
            display: block;
  
            @include mqPhoneLarge {
              margin-right: 0;
            }
          }
          &-initial{
            color: var(--color__text-primary);
            display: block;
          }
        }

        &.active-toggle {
          .user-welcome {
            &:after {
              transform: rotate(180deg);
            }
          }
        }
      }
    }

    // user name
    .user {
      &-welcome {
        position: relative;
        padding-right: 10px;
        top: 4px;
        margin-right: 0;

        @include mqPhoneLarge {
          display: none;
        }
        span {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          display: inline-block;
          width: 135px;
          position: relative;
          color: var(--color__text-link);
          opacity: 0.6;
        }

        &:after {
          position: absolute;
          content: "";
          border-left: 4px solid transparent;
          border-right: 4px solid transparent;
          border-top: 8px solid var(--color__background-link);
          height: 0;
          width: 0;
          right: 0;
          opacity: 0.5;
          top: 5px;
        }
      }
    }

    // user dropdown
    .user {
      &-dropdown {
        // display: block;
        overflow: hidden;
        background: transparent;
        top: calc(100% - 8px);
        min-width: 210px;
        box-shadow: 0px 2px 16px rgba(153, 155, 168, 0.12);
        border-radius: var(--border__radius-desktop);

        ul {
          li {
            > a {
              padding: 16px 24px;
            }
          }

          &:nth-child(1) {
            > li {
              &:first-child {
                > a {
                  padding-top: 24px;
                }
              }
            }
          }

          &:nth-child(3) {
            > li {
              &:last-child {
                > a {
                  padding-bottom: 24px;
                }
              }
            }
          }
        }
      }

      // &-status{
      // 	border-top-left-radius: var(--border__radius-desktop);
      // 	border-top-right-radius: var(--border__radius-desktop);
      // }

      // &-status{
      // 	~ .user-links{
      // 		li {
      // 			&:last-child{
      // 				a{
      // 					padding-bottom: 24px;
      // 				}
      // 			}
      // 		}
      // 	}
      // }
      &-links {
        margin: 0;
        padding: 0;
        background-color: var(--color__background-white);
        svg {
          display: none;
        }
        a {
          display: block;
          font-size: var(--h5__font-size-desktop);
          color: var(--color__text-heading);
          opacity: 1;

          &:hover,
          &:focus {
            opacity: 0.6;
          }
        }
      }

      &-company {
        border-top: 1px solid var(--color__border-hr);
        background-color: white;
        list-style: none outside none;
        margin: 0;
        padding: 0;
        li {
          padding: 16px 24px;
          display: grid;
          gap: 12px;

          grid-template-columns: 48px 1fr;
          align-items: center;

          img {
            width: 48px;
            height: auto;
          }
          span {
            font-size: var(--h5__font-size-desktop);
            color: var(--color__text-heading);
            opacity: 1;
          }
        }
      }
    }
  }
}

// Mobile
.navbar-mobile {
  height: 100%;
  min-width: 320px;
  padding-top: 32px;
  padding-bottom: 32px;
  ul {
    padding: 0 !important;
    margin: 0;
  }
  .navbar-innr {
    flex-flow: column nowrap;
    display: flex;

    .navbar-menu {
      flex: 1;
    }

    &:first-child {
      height: 100%;
      flex: 1;
    }

    + .navbar-innr {
      display: none;
    }
  }
  .navbar-menu {
    flex-flow: column nowrap;
    align-items: flex-start;
    width: 100%;
    li {
      padding: 0;
      border-bottom: 1px solid var(--color__border-hr);
    }

    a {
      padding-left: 12px;
      padding-right: 12px;

      color: var(--color__text-link);
      text-transform: uppercase;
      opacity: 0.6;

      &:before {
        display: none;
      }

      &:hover,
      &:focus {
        background-color: var(--color__background-gray);
        opacity: 1;
        color: var(--color__text-primary);
      }

      &.disable {
        opacity: 0.3;
        pointer-events: none;
      }
    }

    .active {
      a {
        background-color: var(--color__background-gray);
        opacity: 1;
        color: var(--color__text-primary);
      }
    }
  }

  .navbar-btns {
    padding-left: 12px !important;
    padding-right: 12px !important;
    width: 100%;
    margin: 0;

    li {
      display: block;
      width: 100%;
      padding: 0;
    }
    a {
      display: block;
      font-size: 14px !important;
      text-transform: uppercase;
      font-weight: 500;
      line-height: 1;
      padding: 18px 24px;
      text-align: center;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      transition: all 250ms ease;
      text-decoration: none;
      font-weight: 400;
      overflow: hidden;

      background-color: var(--color__background-secondary);

      &:hover,
      &:focus {
        background: var(--color__background-secondary-dark);
      }
      span {
        color: white !important;
        opacity: 1;
      }
    }
    svg {
      display: none;
    }
  }
  .navbar-menu .navbar-menu {
    a {
      padding-left: 24px;
    }
    li:last-child {
      border-bottom: 0;
    }
  }
}

.page-dashboard__header-nav .navbar-submenu-dropdown {
  a {
    font-size: var(--h5__font-size-desktop);
    color: var(--color__text-heading);
    opacity: 1;

    &:hover {
      opacity: 0.6;
    }
  }

  .MuiMenu-paper {
    border: 1px solid #909090;
  }

  .MuiListItem-root {
    padding: 0;

    &:hover {
      background-color: transparent;
    }

    a {
      width: 100%;
      padding: 9px 16px;
      display: block;
    }
  }
}
