.field{
  &__vert{
    &_label{
      &:not(:last-child){
        margin-bottom: 12px;
      }

      & + & {
        margin-left: 0 !important;
      }
    }
  }
}

.card__information_custom{
  p{
    font-size: 14px;
    margin-top: 0 !important;
    &:not(:last-child){
      margin-bottom: 16px;
    }
  }

  ul{
    padding-left: 24px;
    padding-bottom: 32px;
  }
  li{
    font-size: 14px;
    + li{
      margin-top: 6px !important;
    }
  }
}

.field__check{
  label > div > div{
    &:first-child{
      top: 3px;
    }
  }
  label > div > div{
    padding-left: 12px;
    font-family: "Inter", sans-serif !important;
    font-size: 15px;
    font-weight: 500;
    transition: all .2s;
    line-height: 1.6;
    color: #6F767E;
    padding-left: 12px;
  }

  input[type="checkbox"]:checked{
    + div{
      div{
        color: #1A1D1F;
      }
    }
  }
}


.field__checks{
  label{
    &:not(:last-child){
      margin-bottom: 24px;
    }
  }

  label {
    span {
      &:not(:first-child){
        margin-bottom: 16px;
      }
    }
  }

  a{
    color: #2A85FF;
    font-weight: bold;

    &:hover,
    &:focus{
      color: #0069f6;
    }
  }
}