.chat-form {
  display: flex;
}

.chat-input {
  flex-grow: 1;
}

.chat-submit {
  margin-left: 10px;
  flex-shrink: 0;
}