.module{
  &_page{
    &_forwarding{
      position: fixed;
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      align-items: center;
      z-index: 500;
      height: 100vh;
      width: 100%;
    }

    &_status{
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 40px;
      overflow: hidden;
      position: relative;
    }

    &_wrapper{
      min-width: 340px;
      padding-left: 12px;
      padding-right: 12px;
      text-align: center;
    }

    &_circlefirst,
    &_circlethird{
      height: 100px;
      width: 100px;
      position: relative;
      z-index: 2;
      span{
        height: 55px;
        width: 55px;
        display: block;
      }
      img{
        width: 100%;
      }
    }

    &_circlesecond{
      position: relative;
      z-index: 2;
      &:before{
        content: "";
        position: absolute;
        display: block;
        top: 49%;
        left: 49%;
        transform: translateX(-50%) translateY(-50%);
        background-color: inherit;
        border-radius: 50%;
        z-index: 0;
        width: 64px;
        height: 64px;
        animation: pulse-border 1.1s ease-out infinite;
      }

      span{
        z-index: 2;
        position: relative;
      }
    }
    &_title{
      text-align: center;
      font-size: 20px;
      font-weight: 600;
      line-height: 1.6;
      letter-spacing: -.02em;
      margin-bottom: 4px;
    }
    &_text{
      display: block;
      text-align: center;
    }

    &_pulse{
      height: 20px;
      position: absolute;
      top: calc(50% - 20px);
      transform: translateY(50%);
      width: 100%;
      z-index: 0;

      &:before{
        position: absolute;
        content: "";
        display: block;
        background:linear-gradient(90deg, rgba(231,241,255,.5), rgba(231,241,255,1) 27%, rgba(0,105,246,.1) 65%, rgba(0,105,246,1) 85%);
        background-size: 300% 200%;
        width: 80%;
        left: 50%;
        transform: translateX(-50%);
        height: 2px;
        opacity: .55;
        animation: progress-animation 1.1s linear infinite;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .module{
    &_page{
      &_title{
        font-size: 18px;
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .module{
    &_page{
      &_wrapper{
        min-width: initial;
        width: 100%;
      }
    }
  }
}

@keyframes pulse-border{
  0%{
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 1;
  }
  100%{
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
    opacity: 0;
  }
}

@keyframes progress-animation{
  0%{
    background-position: 100%;
  }
  100%{
    background-position: 0;
  }
}