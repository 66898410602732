.module{
  &_page{
    &_success{
      position: fixed;
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      align-items: center;
      z-index: 500;
      height: 100vh;
      width: 100%;
    }

    &_wrapper{
      min-width: 340px;
      padding-left: 12px;
      padding-right: 12px;
      text-align: center;
    }

    &_status{
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      align-items: center;
      margin-bottom: 40px;
    }

    &_title{
      text-align: center;
      font-size: 20px;
      font-weight: 600;
      line-height: 1.6;
      letter-spacing: -.02em;
      max-width: 478px;
      margin: 0 auto 4px;
    }

    &_text{
      display: block;
      text-align: center;
    }
  }

  &_check{
    &_container{
      width: 126px;
      height: 126px;
      display: flex;
      flex-flow: column;
      align-items: center;
      justify-content: space-between;
    }

    &_background{
      width: 105px;
      height: 105px;
      background: linear-gradient(to bottom right, #83BF6E, #41d67c);
      box-shadow: 0px 0px 0px 65px rgba(255,255,255, .25) inset, 0px 0px 0px 65px rgba(255,255,255, 0.25) inset;
      transform: scale(0.84);
      border-radius: 50%;
      animation: animateContainer 0.75s ease-out forwards 0.75s;
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 0;

      svg{
        width: 65%;
        transform: translateY(0.25rem);
        stroke-dasharray: 80;
        stroke-dashoffset: 80;
        animation: animateCheck 0.35s forwards 1.25s ease-out;
      }
    }

    &_shadow{
      bottom: calc(-15% - 5px);
      left: 0;
      border-radius: 50%;
      background: radial-gradient(closest-side, #83BF6E, transparent);
      animation: animateShadow 0.75s ease-out forwards 0.75s;
    }
  }
}

@media screen and (max-width: 767px) {
  .module{
    &_page{
      &_title{
        font-size: 18px;
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .module{
    &_page{
      &_wrapper{
        min-width: initial;
        width: 100%;
      }
    }
  }
}

@keyframes animateContainer {
	0% {
		opacity: 0;
		transform: scale(0);
		box-shadow: 0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset,
			0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset;
	}
	25% {
		opacity: 1;
		transform: scale(0.9);
		box-shadow: 0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset,
			0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset;
	}
	43.75% {
		transform: scale(1.15);
		box-shadow: 0px 0px 0px 43.334px rgba(255, 255, 255, 0.25) inset,
			0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset;
	}
	62.5% {
		transform: scale(1);
		box-shadow: 0px 0px 0px 0px rgba(255, 255, 255, 0.25) inset,
			0px 0px 0px 21.667px rgba(255, 255, 255, 0.25) inset;
	}
	81.25% {
		box-shadow: 0px 0px 0px 0px rgba(255, 255, 255, 0.25) inset,
			0px 0px 0px 0px rgba(255, 255, 255, 0.25) inset;
	}
	100% {
		opacity: 1;
		box-shadow: 0px 0px 0px 0px rgba(255, 255, 255, 0.25) inset,
			0px 0px 0px 0px rgba(255, 255, 255, 0.25) inset;
	}
}

@keyframes animateCheck {
	from {
		stroke-dashoffset: 80;
	}
	to {
		stroke-dashoffset: 0;
	}
}

@keyframes animateShadow {
	0% {
		opacity: 0;
		width: 100%;
		height: 15%;
	}
	25% {
		opacity: 0.25;
	}
	43.75% {
		width: 40%;
		height: 7%;
		opacity: 0.35;
	}
	100% {
		width: 85%;
		height: 15%;
		opacity: 0.25;
	}
}