
.field{
  &__vert{
    &_label{
      &:not(:last-child){
        margin-bottom: 12px;
      }

      & + & {
        margin-left: 0 !important;
      }
    }

    ul{
      list-style: disc;
      padding-left: 16px;
      padding-top: 8px;
    }
  }
}

.field__date{
  > div > div{
    background-color: white !important;
    border-color: white !important;
  }

  button{
    background-color: white !important;
  }
}

.select{
  > div > div{
    background-color: white !important;
  }
}

.custom_popper {
  > div > div {
    color: inherit;
  }
}

.textarea{
  > div > div{
    background-color: white !important;
  }
}

.upload{
  &_result{
    margin-top: -16px;
  }
}