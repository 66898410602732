.module{
  &__image{
    &_icon{
      height: 20px;
      margin-left: 4px;
    }
  }

  &__field{
    &_information{
      max-width: 100%;
    }
  }

  &__pressitem{
    @media screen and (max-width: 320px) {
      flex-flow: column nowrap;
      align-items: flex-start;
    }
  }

  &__pressdetails{
    flex: 1;

    @media screen and (max-width: 320px) {
      margin-top: 14px;
    }
  }

  &__presspost{
    max-width: 100%;
    font-weight: 400;
  }

  &__presssocials{
    
  }

  &__copyfeedback{
    flex: 1;
    border-color: #EFEFEF !important;
    border-radius: 12px !important;
    display: flex !important;

    input[type="text"]{
      background: transparent;
      flex: 1;
    }
  }
}