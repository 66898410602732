.select{
  > div > div{
    background-color: white !important;
  }
}

.upload{
  &_result{
    margin-top: -16px;
  }
}