.subapp{
  &__head{
    &::before{
      display: none !important;
    }
  }

  &__body{
    max-width: 260px;
  }

  &__notification{
    cursor: pointer;
    // padding-left: 0;
    // padding-right: 0;
    &::after{
      display: none;
    }

    &:hover,
    &:focus{
      text-decoration: none !important;
    }
  }
  &__details{
    padding-left: 0;
  }
}