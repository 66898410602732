.director{
  &__header{
    align-items: center !important;
  }
  
  &__contentstatus{
    margin-bottom: 6px;
    &:not(:first-child){
      margin-left: 6px;
    }

    &_wrapper{
      flex: 1;
      text-align: right;
    }
  }
}


.col{
  &__vert{
    align-items: center !important;
    padding-bottom: 12px !important;
  }
}

.select{
  > div > div{
    background-color: white !important;
  }
}