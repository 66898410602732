.module{
  &__page{
    &_loading{
      position: fixed;
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      align-items: center;
      z-index: 500;
      height: 100vh;
      width: 100%;
    }
  }

  &__loading{
    &_wrapper{
      width: 142px;
      height: 142px;
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      align-items: center;
      padding: 12px;
      position: relative;
      img{
        width: 100%;
        position: relative;
      }
    }

    &_circle{
      height: 142px;
      width: 142px;
      border-radius: 50%;
      left: 0;
      top: 0;
      position: absolute;
      display: block;
      border: 3px solid #2A85FF;
      border-left-color: transparent;
      animation: rotate 1.4s ease 0.5s infinite;
    }
  }
}


@keyframes rotate {
  0%{
    transform: rotate(360deg);
  }
}