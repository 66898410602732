// Transition default
@mixin transition-default() {
  transition: all 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
}

// Media Queries
@mixin mqDesktop() {
  // Desktop Max width: 1140px
  @media (max-width: 1140px) {
    @content;
  }
}

@mixin mqTabletLandscape() {
  @media (max-width: 1024px) {
    @content;
  }
}

@mixin mqTabletPotrait() {
  @media (max-width: 768px) {
    @content;
  }
}

@mixin mqPhoneLarge() {
  @media (max-width: 578px) {
    @content;
  }
}

@mixin mqPhoneSmall() {
  @media (max-width: 328px) {
    @content;
  }
}

// font size media queries change
// -var(--paragraph__font-size-desktop-large);
// tablet => 16px
